import axios from '@axios'
import Proposal from '@/mixins/classes/proposal'

export default {
  namespaced: true,
  state: {
    proposal: {},
    proposals: [],
  },
  getters: {
    proposal: state => state.proposal,
    proposals: state => state.proposals,
    Proposal: state => new Proposal(state.proposal),
  },
  mutations: {
    setProposal(state, proposal) {
      state.proposal = proposal
    },
    setProposals(state, proposals) {
      state.proposals = proposals
    },
    updateProposalsItem(state, proposal) {
      const index = state.proposals.findIndex(item => item.id === proposal.data.id)
      state.proposals[index] = proposal.data
    },
    deleteProposal(state, id) {
      const index = state.proposals.findIndex(item => item.id === id)
      state.proposals.splice(index, 1)
    },
  },
  actions: {
    // Service Proposals
    async addRequestProposal(ctx, proposal) {
      const response = await axios.post('/proposals', proposal).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
        ctx.commit('setProposal', response.data.data)
      }
      return response
    },
    async updateRequestProposal(ctx, data) {
      const response = await axios.patch(`/proposals/${data.proposalId}`, data.payload).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setProposal', response.data.data)
        response.statusText = 'updated'
      }
      return response
    },
    async fetchProposals(ctx, queryParams) {
      const response = await axios.get('/events', { params: queryParams }).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setProposals', response.data.data)
      }
      return response
    },
    async fetchMyRequestProposals(ctx, data) {
      const includes = ['user', 'request'].filter(include => data.include.includes(include))
      const response = await axios.get(`/proposals?include=${includes}`, data.queryParams).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setProposals', response.data.data)
      }
      return response
    },

    async acceptProposal(ctx, { id }) {
      const response = await axios.post(`/events/${id}/accept`).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setProposal', response.data.data)
      }
      return response
    },

    async declineProposal(ctx, { id, decline_reason }) {
      const response = await axios.post(`/events/${id}/decline`, { decline_reason }).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setProposal', response.data.data)
      }
      return response
    },

    async withdrawProposal(ctx, { id, withdraw_reason }) {
      const response = await axios.post(`/events/${id}/withdraw`, { withdraw_reason }).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setProposal', response.data.data)
      }
      return response
    },

    async deleteProposal(ctx, { id }) {
      const response = await axios.delete(`/events/${id}`).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('deleteProposal', id)
      }
      return response
    },

  },
}
