class Proposal {
  static get modelName() {
    return 'Proposal'
  }

  constructor(proposal) {
    Object.assign(this, proposal)
  }
}

export default Proposal
