import mock from '@/@fake-db/mock'

const date = new Date()
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

const data = {
  events: [
    // My proposal to request #17
    {
      id: 1,
      organizer: 5, // Надавач Послуг 3
      subject: 17,
      title: 'Не працює пральна машина / моя (Надавач Послуг 3) пропозиція1',
      start: '2022-12-10T19:00:51.000000Z',
      end: '2022-12-10T20:34:51.000000Z',
      allDay: false,
      extendedProps: {
        calendar: 'Services',
        participants: [{ avatar: require('@/assets/images/avatars/5-small.png'), name: 'Vi Admin', id: 2 }], // Request author ID
        appointment: '2022-12-10T19:00:51.000000Z',
        post_status: 'Draft',
        min_price: 100,
        accept_date: '',
        cancel_date: '',
        cancel_reason: '',
        cancel_by: '',
        decline_date: '',
        decline_reason: '',
        complete_date: '',
        withdraw_date: '',
        withdraw_reason: '',
      },
      request: {
        id: 17,
        user_id: 2,
        point_type_id: 2,
        point_purpose_id: 15,
        name: 'Не працює пральна машина',
        description: 'опис описописописопис опис опис опис',
        lng: 32.0670289,
        lat: 49.4442183,
        location_radius: 0,
        estimated_time_of_stay: 0,
        post_status: 'draft',
        address: {
          country: 'Україна',
          country_code: 'ua',
          state: 'Черкаська область',
          locality: 'Черкаси',
          locality_type: 'city',
          road: 'Смілянська вулиця',
          house_number: '2',
          apartment_number: null,
          postcode: '18029',
          address_confirmed: true,
        },
        user: {
          id: 2,
          name: 'Vi Admin',
          roles: [
            'admin',
            'customer',
          ],
          ability: [
            {
              subject: 'all',
              action: 'manage',
            },
          ],
        },
      },
    },
    // My personal event
    {
      id: 2,
      organizer: 5, // Надавач Послуг 3
      subject: '',
      appointment: '',
      url: '',
      title: 'Meeting With Friends / Надавач Послуг 3',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
      allDay: false,
      extendedProps: {
        calendar: 'Personal',
        participants: [],
      },
    },
    // Event created by Service Provider for my request
    {
      id: 3,
      organizer: 4, // Надавач Послуг
      subject: 17,
      appointment: '2022-12-10T10:34:51.000000Z',
      url: '',
      title: 'Не працює холодильник',
      allDay: false,
      start: '2022-12-10T9:34:51.000000Z',
      end: '2022-12-10T15:34:51.000000Z',
      extendedProps: {
        calendar: 'Services',
        participants: [{ avatar: require('@/assets/images/avatars/1-small.png'), name: 'Надавач Послуг 3', id: 5 }], // Надавач Послуг 3
        request: {
          id: 22,
          user_id: 5, // Надавач Послуг 3
          point_type_id: 2,
          point_purpose_id: 21,
          name: 'Завис ноутбук',
          description: '',
          lng: 32.0670289,
          lat: 49.4442183,
          location_radius: 0,
          estimated_time_of_stay: 0,
          post_status: 'draft',
          address: {
            country: 'Україна',
            country_code: 'ua',
            state: 'Черкаська область',
            locality: 'Черкаси',
            locality_type: 'city',
            road: 'Смілянська вулиця',
            house_number: '2',
            apartment_number: null,
            postcode: '18029',
            address_confirmed: true,
          },
          user: {
            id: 4,
            name: 'Надавач Послуг',
            roles: [
              'customer',
            ],
            ability: [
              {
                subject: 'ServiceOffer',
                action: 'update',
              },
            ],
          },
        },
      },

    },
    {
      id: 4,
      organizer: 5, // Надавач Послуг 3
      subject: '',
      appointment: '',
      url: '',
      title: 'СТО Надавач / Послуг 3',
      start: new Date(date.getFullYear(), date.getMonth() + 1, -11),
      end: new Date(date.getFullYear(), date.getMonth() + 1, -10),
      allDay: true,
      extendedProps: {
        calendar: 'Personal',
        participants: [0],
      },
    },
    {
      id: 5,
      organizer: 2, // Vi Admin
      subject: '',
      appointment: '',
      url: '',
      title: "Doctor's Appointment / Vi Admin",
      start: '2022-12-22T6:40:51.000000Z',
      end: '2022-12-22T7:40:51.000000Z',
      allDay: false,
      extendedProps: {
        calendar: 'Personal',
        participants: [0],
      },
    },
    {
      id: 6,
      organizer: 4, // Надавач Послуг
      subject: 17,
      appointment: '2022-12-10T19:34:51.000000Z',
      url: '',
      title: 'Не працює холодильник',
      start: '2022-12-10T19:00:51.000000Z',
      end: '2022-12-10T20:34:51.000000Z',
      allDay: false,
      extendedProps: {
        calendar: 'Services',
        participants: [{ avatar: require('@/assets/images/avatars/1-small.png'), name: 'Надавач Послуг 3', id: 5 }], // Request author ID
      },
      request: {
        id: 17,
        user_id: 2,
        point_type_id: 2,
        point_purpose_id: 15,
        name: 'Завис ноутбук /Надавач Послуг',
        description: 'опис описо sd  sdfsdf dfписописопис опис опис опис',
        lng: 32.0670289,
        lat: 49.4442183,
        location_radius: 0,
        estimated_time_of_stay: 0,
        post_status: 'draft',
        address: {
          country: 'Україна',
          country_code: 'ua',
          state: 'Черкаська область',
          locality: 'Черкаси',
          locality_type: 'city',
          road: 'Смілянська вулиця',
          house_number: '2',
          apartment_number: null,
          postcode: '18029',
          address_confirmed: true,
        },
        user: {
          id: 4,
          name: 'Надавач Послуг',
          roles: [
            'admin',
            'customer',
          ],
          ability: [
            {
              subject: 'all',
              action: 'manage',
            },
          ],
        },
      },
    },

  ],
}

// ------------------------------------------------
// GET: Return calendar events
// ------------------------------------------------
mock.onGet('/apps/calendar/events').reply(config => {
  // Get requested calendars as Array
  const calendars = config.params.calendars.split(',')

  return [200, data.events.filter(event => calendars.includes(event.extendedProps.calendar))]
})

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost('/apps/calendar/events').reply(config => {
  // Get event from post data
  const { event } = JSON.parse(config.data)

  const { length } = data.events
  let lastIndex = 0
  if (length) {
    lastIndex = data.events[length - 1].id
  }
  event.id = lastIndex + 1

  data.events.push(event)

  return [201, { event }]
})

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost(/\/apps\/calendar\/events\/\d+/).reply(config => {
  const { event: eventData } = JSON.parse(config.data)

  // Convert Id to number
  eventData.id = Number(eventData.id)

  const event = data.events.find(e => e.id === Number(eventData.id))
  Object.assign(event, eventData)

  return [200, { event }]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/apps\/calendar\/events\/\d+/).reply(config => {
  // Get event id from URL
  let eventId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  eventId = Number(eventId)

  const eventIndex = data.events.findIndex(e => e.id === eventId)
  data.events.splice(eventIndex, 1)
  return [200]
})
