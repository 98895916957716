import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import ability from '@/libs/acl/ability'
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'

export default {
  namespaced: true,
  state: {
    user: {},
    accountSettings: {},
  },
  getters: {
    user: state => state.user,
    accountSettings: state => state.accountSettings,
  },
  mutations: {
    setUser(state, user) {
      localStorage.setItem('userData', JSON.stringify({
        id: user.id,
        role: user.role,
      }))
      ability.update(user.ability)
      state.user = user
      GlobalEventEmitter.$emit('userPermissionsUpdated')
    },
    clearUser(state) {
      state.user = {}
    },
    setAccountSettings(state, settings) {
      state.accountSettings = settings
    },
    setAvatar(state, avatar) {
      state.user.avatar = avatar
    },
    deleteAvatar(state) {
      state.user.avatar = null
    },

  },
  actions: {
    setUser(ctx, user) {
      ctx.commit('setUser', user)
    },
    setAccountSettings(ctx, accountSettings) {
      ctx.commit('setAccountSettings', accountSettings)
    },
    async getOtp(ctx, phone) {
      return axios.post('/phone/send-otp', phone)
    },
    async getResetOtp(ctx, phone) {
      return axios.post('/phone/send-reset-otp', phone)
    },
    async verifyOtp(ctx, payload) {
      return axios.post('/phone/verify-otp', payload)
    },
    async verifyResetOtp(ctx, payload) {
      return axios.post('/phone/verify-reset-otp', payload)
    },
    async getUserCurrent({ getters, dispatch }) {
      if (Object.keys(getters.user).length !== 0) {
        return { status: 200, data: getters.user }
      }
      if (!useJwt.getToken() || !useJwt.getRefreshToken()) {
        return { status: 400, data: null }
      }
      const response = await axios.get('/users/current?include=ability,roles,languages,phones,service_offers.point_type,service_offers.point_purposes,service_offers.media_files').catch(error => console.log(error))
      if (response.status === 200) {
        const userData = response.data.data
        dispatch('setUser', userData)
        return ({ status: response.status, data: userData })
      }
      return { status: 0 }
    },
    async fetchUserCurrent({ dispatch }) {
      if (!useJwt.getToken() || !useJwt.getRefreshToken()) {
        return { status: 400, data: null }
      }
      const response = await axios.get('/users/current?include=ability,roles,languages,phones,service_offers.point_type,service_offers.point_purposes,service_offers.media_files').catch(error => console.log(error))
      if (response.status === 200) {
        const userData = response.data.data
        dispatch('setUser', userData)
        return ({ status: response.status, data: userData })
      }
      return { status: 0 }
    },
    async fetchAccountSettings(ctx) {
      const response = await axios.get('/account-settings/current').catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setAccountSettings', response.data)
      }
      return response
    },

    async updateAccountSettings(ctx, account) {
      const response = await axios.patch('/account-settings', account)
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'updated'
        ctx.commit('setAccountSettings', account.data)
      } else {
        response.statusText = 'error'
      }
      return response
    },
    async addAvatar(ctx, data) {
      const response = await axios.post('/account-settings/avatar', data.img).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
      }
      return response
    },
    async deleteAvatar(ctx, data) {
      const response = await axios.delete('/account-settings/avatar')
        .catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'deleted'
      }
      return response
    },

    async sendPasswordResetEmail(ctx, email) {
      const response = await axios.post('/password/email', { email })
      return response
    },
    async sendPasswordResetRequest(ctx, data) {
      const response = await axios.post('/password/reset', data)
      return response
    },

  },
}
