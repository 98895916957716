import Vue from 'vue'
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginVue from '@bugsnag/plugin-vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import * as VueGoogleMaps from 'vue2-google-maps'

import i18n from '@/libs/i18n'
import { SlickList, SlickItem } from 'vue-slicksort'
import VueAnalytics from 'vue-analytics'
import VueMeta from 'vue-meta'
import VueMask from 'v-mask'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

import './registerServiceWorker'
// Bugsnag.start({
//   apiKey: 'cff515d0ff5d43e05d2786ca0a77e201',
//   plugins: [new BugsnagPluginVue()],
// })
// const bugsnagVue = Bugsnag.getPlugin('vue')
// bugsnagVue.installVueErrorHandler(Vue)
// VueGoogleMaps
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
//     libraries: 'places',
//   },
// })
Vue.use(VueMask)
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS_KEY,
  router,
})

Vue.config.productionTip = false

Vue.component('vfa-sortable-list', SlickList)
Vue.component('vfa-sortable-item', SlickItem)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
