<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <p-w-a-prompt />
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import PWAPrompt from '@/layouts/components/PWAPrompt.vue'
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import i18n from '@/libs/i18n'

import { countriesIso } from '@/utils/location/phoneCodeCountries'
import { Workbox } from 'workbox-window'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
import { useUserLocation, useUserUi } from '@/views/apps/user/useUser'
export default {
  components: {
    PWAPrompt,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    Workbox,

    ScrollToTop,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'OneTimeJob',
    // all titles will be injected into this template
    titleTemplate: '%s | OneTimeJob',
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      { charset: 'utf-8' },
      {
        property: 'og:title',
        content: 'Online service for the management of short-term and one-time jobs',
        template: chunk => `${chunk} | OneTimeJob`,
        vmid: 'og:title',
      },
    ],
    noscript: [
      { innerHTML: 'Online service for the management of short-term and one-time jobs (this website requires JavaScript)' },
    ],
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },

  },

  created() {
    // if ('service-worker' in navigator) {
    //   this.workbox = new Workbox('/service-worker.js')
    //
    //   this.workbox.addEventListener('waiting', event => {
    //     this.refreshApp()
    //   })
    //   this.workbox.register()
    // }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  methods: {
    // refreshApp() {
    //   this.workbox.addEventListener('controlling', () => {
    //     window.location.reload()
    //   })
    //   this.workbox.messageSkipWaiting()
    // },

  },
  setup() {
    const workbox = ref({})
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout
    const { setUserCountryData } = useUserLocation()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })
    // Init option Countries List
    store.commit('appOptions/INIT_COUNTRIES_LIST')
    // Init option Languages List
    store.commit('appOptions/INIT_LANGUAGES_LIST')

    const setLocale = () => {
      let navLang = null
      if (typeof navigator !== 'undefined') {
        navLang = navigator.language || window.navigator.language
      }

      let locale = localStorage.getItem('locale')

      if (typeof locale === 'string' && locale.length === 2 && locale !== i18n.locale) {
        i18n.locale = locale
      }

      if (!locale && navLang) {
        const lang = navLang.slice(0, 2)
        locale = Object.keys(i18n.messages).includes(lang) ? lang : 'en'
        localStorage.setItem('locale', locale)
        i18n.locale = locale
      }
    }


    setLocale()
    setUserCountryData()

    return {
      skinClasses,
      enableScrollToTop,
      workbox,
    }
  },
}
</script>
