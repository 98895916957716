import store from '@/store/index'
import ability from '@/libs/acl/ability'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = async () => {
  const response = await store.dispatch('account/getUserCurrent').catch(error => console.log(error))
  return response.status === 200
}

export const getUserData = () => store.getters['account/user']

export const getHomeRouteForLoggedInUser = () => {
  if (ability.can('manage', 'all')) {
    return { name: 'pages-account-setting' }
  }
  if (ability.can('update', 'Account')) {
    return { name: 'pages-account-setting' }
  }
  return { name: 'auth-login' }
}
