import axios from '@axios'
import Point from '@/mixins/classes/point'
import { extendPointTypesAndPurposes } from '@/mixins/functions'

export default {
  namespaced: true,
  state: {
    pointTypesAndPurposes: {},
    point: {},
    offers: [],
  },
  getters: {
    pointTypesAndPurposes: state => state.pointTypesAndPurposes,
    point: state => state.point,
    Point: state => new Point(state.point),
    offers: state => state.offers,
  },
  mutations: {
    setPointTypesAndPurposes(state, pointTypesAndPurposes) {
      state.pointTypesAndPurposes = pointTypesAndPurposes
    },
    setPoint(state, point) {
      state.point = point
    },
    deletePoint(state) {
      state.point = {}
    },
    setOffers(state, offers) {
      state.offers = offers
    },
  },
  actions: {
    // Point Options
    setPointTypesAndPurposes(ctx, pointTypesAndPurposes) {
      ctx.commit('setPointTypesAndPurposes', pointTypesAndPurposes)
    },
    async fetchPointPurposes(ctx, queryParams) {
      const response = await axios.get('/point-purposes?include=point_type,tags', {
        params: queryParams,
      }).catch(error => console.log(error))
      return response
    },
    async getPointTypesAndPurposes({ getters, dispatch }) {
      if (Object.keys(getters.pointTypesAndPurposes).length !== 0) {
        return { status: '200', data: getters.pointTypesAndPurposes }
      }

      const response = await axios.get('/point-types?include=point_purposes').catch(error => console.log(error))
      if (response.status === 200) {
        // const pointTypesAndPurposes = extendPointTypesAndPurposes(response.data.data)
        const pointTypesAndPurposes = response.data.data
        dispatch('setPointTypesAndPurposes', pointTypesAndPurposes)
        return ({ status: response.status, data: pointTypesAndPurposes })
      }
      return { status: 0 }
    },
    // Points
    async addPoint(ctx, point) {
      const response = await axios.post('/points', point).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
        ctx.commit('setPoint', response.data.data)
      }
      return response
    },
    async updatePoint(ctx, point) {
      const response = await axios.patch(`/points/${point.id}`, point.data).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setPoint', response.data.data)
        response.statusText = 'updated'
      }
      return response
    },
    async fetchPoint(ctx, { id }) {
      const response = await axios.get(`/points/${id}?include=user,point_type,point_purposes,main_image,media_files`).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setPoint', response.data.data)
      }
      return response
    },
    async deletePoint(ctx, { id }) {
      const response = await axios.delete(`/points/${id}`).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'deleted'
      }
      return response
    },
    async fetchPoints(ctx, queryParams) {
      const response = await axios.get('/points?include=user,point_type,point_purposes,main_image,media_files', {
        params: queryParams,
      }).catch(error => console.log(error))
      return response
    },
    async fetchPublicServiceRequests(ctx, queryParams) {
      const response = await axios.get('/public/service-requests?include=point_type,point_purposes,main_image', {
        params: queryParams,
      }).catch(error => console.log(error))
      return response
    },
    // Service Offers
    async addServiceOffer(ctx, point) {
      const response = await axios.post('/service-offers', point).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
        ctx.commit('setPoint', response.data.data)
      }
      return response
    },
    async updateServiceOffer(ctx, point) {
      const response = await axios.patch(`/service-offers/${point.id}`, point).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setPoint', response.data.data)
        response.statusText = 'updated'
      }
      return response
    },
    async fetchServiceOffer(ctx, { id }) {
      const response = await axios.get(`/service-offers/${id}?include=user.phones,user.languages,point_type,point_purposes,main_image,media_files,tags`).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setPoint', response.data.data)
      }
      return response
    },

    async deleteServiceOffer(ctx, { id }) {
      const response = await axios.delete(`/service-offers/${id}`).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'deleted'
      }
      return response
    },
    async fetchServiceOffers(ctx, queryParams) {
      const response = await axios.get('/service-offers?include=user,point_type,point_purposes,main_image,media_files', {
        params: queryParams,
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setOffers', response.data.data.map($item => new Point($item)))
      }
      return response
    },
    async fetchServiceOffersPublic(ctx, queryParams) {
      const response = await axios.get('/public/service-offers?include=user,user.languages,media_files', {
        params: queryParams,
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setOffers', response.data.data.map($item => new Point($item)))
      }
      return response
    },

    // Service Requests
    async addServiceRequest(ctx, point) {
      const response = await axios.post('/service-requests', point).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
        ctx.commit('setPoint', response.data.data)
      }
      return response
    },
    async addServiceRequestManaged(ctx, point) {
      const response = await axios.post('/service-requests/managed', point).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
        ctx.commit('setPoint', response.data.data)
      }
      return response
    },
    async updateServiceRequest(ctx, point) {
      const response = await axios.patch(`/service-requests/${point.id}`, point).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setPoint', response.data.data)
        response.statusText = 'updated'
      }
      return response
    },
    async fetchServiceRequest(ctx, { id }) {
      const response = await axios.get(`/service-requests/${id}?include=user.phones,user.languages,point_type,point_purposes,main_image,media_files,events,manager.phones,manager.languages,receiver.phones,receiver.languages`).catch(error => {
        if (error.response.status === 404) {
          return error.response
        }
        console.log(error)
      })
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('setPoint', response.data.data)
      }
      return response
    },
    async deleteServiceRequest(ctx, { id }) {
      const response = await axios.delete(`/service-requests/${id}`).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        ctx.commit('deletePoint')
        response.statusText = 'deleted'
      }
      return response
    },
    async closeServiceRequest(ctx, { id, close_reason }) {
      const response = await axios.post(`/service-requests/${id}/close`, { data: { close_reason } }).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'closed'
      }
      return response
    },
    async fetchServiceRequests(ctx, queryParams) {
      const response = await axios.get('/service-requests?include=user,point_type,point_purposes,main_image,media_files,events,manager,receiver', {
        params: queryParams,
      }).catch(error => console.log(error))
      return response
    },
    async fetchSearchServiceRequests(ctx, queryParams) {
      const response = await axios.get('/search-service-requests?include=user,point_type,point_purposes,main_image,media_files,events,manager,receiver', {
        params: queryParams,
      }).catch(error => console.log(error))
      return response
    },
    async fetchSearchServiceRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/search-service-requests/${id}?include=user.phones,user.languages,user.service_offers.point_type,point_type,user.service_offers.point_purposes,point_purposes,main_image,media_files,manager.phones,manager.languages,receiver.phones,receiver.languages`)
          .then(response => {
            const successStatuses = [200, 201]
            if (successStatuses.includes(response.status)) {
              ctx.commit('setPoint', response.data.data)
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // Images
    async addImages(ctx, formData) {
      const response = await axios.post('/images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'added'
      }
      return response
    },
    async deleteImages(ctx, data) {
      const response = await axios.delete(`/points/${data.pointId}/images?image_ids=${data.imageIds}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).catch(error => console.log(error))
      const successStatuses = [200, 201]
      if (successStatuses.includes(response.status)) {
        response.statusText = 'deleted'
      }
      return response
    },
  },
}
