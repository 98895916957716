<template>
  <div />
</template>

<script>

import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'
import logo from '@/assets/images/logo/logo.svg'
import 'animate.css'

export default {
  data() {
    return {
      deferredPrompt: null,
      logo,
    }
  },
  created() {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e
    })
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null
    })

    GlobalEventEmitter.$on('displayPwaPrompt', () => {
      if (this.deferredPrompt) {
        this.displayPrompt()
      }
    })
  },
  methods: {
    displayPrompt() {
      setTimeout(() => {
        this.$swal({
          title: this.$t('Get our app'),
          text: this.$t('It won\'t take up space on your device and also works offline!'),
          imageUrl: this.logo,
          imageAlt: this.$t('Get our app'),
          showCancelButton: true,
          confirmButtonText: this.$t('Install'),
          cancelButtonText: this.$t('Dismiss'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showClass: {
            popup: 'animate__animated animate__fadeIn',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.install()
          } else {
            this.dismiss()
          }
        })
      },
      1000)
    },
    dismiss() {
      this.deferredPrompt = null
    },
    install() {
      this.deferredPrompt.prompt()
    },
  },

}
</script>
